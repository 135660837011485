<template>
  <div class="select" :tabindex="index">
    <div class="selected" :class="{dropdown: dropdown}" @click="dropdown = !dropdown">
      <div class="helper">
        <p>{{ selected | uppercase }}</p>
      </div>
      <div class="icon" :class="[dropdown ? 'up' : 'down']">
        <img src="../assets/icons/chevron_down.png" />
      </div>
    </div>
    <div class="items" :class="{selectHide: !dropdown}">
      <div
        class="item"
        v-for="(option, index) of options"
        :key="index"
        @click="handleOption(option)"
      >
        <p>{{ option | uppercase }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CustomSelect",
  props: {
    options: {
      type: Array,
      required: true
    },
    index: {
      type: Number,
      default: 0
    },
    value: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      selected: "",
      dropdown: false
    };
  },
  created() {
    this.selected = this.value || this.options[0];
    this.$emit("input", this.selected);
  },
  methods: {
    handleOption(newOption) {
      this.selected = newOption;
      this.dropdown = false;
      localStorage.selectedLang = newOption;
      this.$emit("input", newOption);
    }
  }
};
</script>

<style scoped>
.select {
  position: relative;
  width: 100% !important;
  text-align: left;
  outline: #b68a28;
  display: flex;
}
.helper {
  display: flex;
  flex-direction: row;
  position: relative;
  align-items: center;
  min-height: 2.5rem;
}
.icon {
  margin: auto auto auto 8px;
  width: 30px;
  justify-content: center;
  display: flex;
}
.up {
  transform: rotate(-180deg);
}
.icon img {
  height: 12px;
  filter: invert(1);
}
.helper p {
  margin: 0;
}
.selected {
  background-color: transparent;
  display: flex;
  flex-direction: row;
  color: white;
  width: 100%;
  padding-left: 8px;
  cursor: pointer;
  user-select: none;
}
.select:focus {
  outline: none;
  box-shadow: 0px 0px 5px #61c5fa;
}
.selected:after {
  position: absolute;
  content: "";
  width: 0;
  height: 0;
}
.items {
  z-index: 2;
  color: #000000;
  overflow: hidden;
  position: absolute;
  background-color: #ffffff;
  top: 2.65rem;
  left: 0;
  right: 0;
  box-shadow: 0 1px 0 0 black;
}
.item {
  z-index: 2;
  display: flex;
  align-items: center;
  color: #000000;
  height: 100%;
  padding: 0 8px;
  height: 2.5rem;
  cursor: pointer;
  user-select: none;
}
.item:hover {
  background-color: rgb(232, 240, 254) !important;
}
.selectHide {
  display: none;
}
</style>